* {
    color: #f2f3eb;
}

.card-title {
    font-size: 1rem;
}

.featured-card {
    background-color: black;
    border-radius: 0;
}

.featured-header {
    width: 100%;
    border-radius: 0%;
    background-color: #0a69c9;
    font-size: 1.2rem;
    font-weight: 300;
    border-width: 0;
}

.featured-body {
    width: 75%;
    margin: auto;
    font-size: 1rem;
}

.dash-light {
    height: 18rem;
    width: 15rem;
    margin: auto;
}

.warning-light {
    stroke: #ffa500;
    font-size: 5rem;
    text-shadow: 0 0 5px #f2f3eb;
    margin-left: 2rem;
}
