.custom-nav {
    background-color: #f2f3eb;
    width: 100%;
    font-size: medium;
}

.brand {
    margin-right: 0;
}

.custom-nav > a {
    position: relative;
    width: fit-content;
    font-size: 12pt;
    height: min-content;
}

.hero {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.nav-banner {
    margin-left: 10%;
    margin-right: auto;
}

.company-title {
    color: #f2f3eb;
    font-family: 'Mattone-150';
    font-size: 3.3rem;
    font-weight: 500;
    letter-spacing: 2px;
    margin-top: 2rem;
    text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
             0px 8px 13px rgba(0,0,0,0.1),
             0px 18px 23px rgba(0,0,0,0.1);
}

.company-subtitle {
    color: #f2f3eb;
    font-family: 'Mattone-150';
    font-weight: 500;
    letter-spacing: 2px;
    text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
             0px 8px 13px rgba(0,0,0,0.1),
             0px 18px 23px rgba(0,0,0,0.1); 
}

.contact-number {
    letter-spacing: 6px;
    margin-left: 8rem;
    margin-right: 3rem;
}

.cta-button {
    color: #f2f3eb;
    background-color: #0a69c9;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
}

.wa-icon {
    font-size: 1.4rem;
    fill: #0a69c9;
    margin-left: 0.5rem;
}

/*------------------------------------------
  Responsive Grid Media Queries - 1280, 1024, 768, 480
   1280-1024   - desktop (default grid)
   1024-768    - tablet landscape
   768-480     - tablet 
   480-less    - phone landscape & smaller
--------------------------------------------*/
@media all and (min-width: 1024px) and (max-width: 1280px) {
    .custom-nav > a {
        position: relative;
        width: fit-content;
        font-size: 9pt;
        height: min-content;
    }

    .contact-number {
        letter-spacing: 6px;
        margin-left: 4rem;
        margin-right: 1rem;
    }
}
 
@media all and (min-width: 768px) and (max-width: 1024px) {
    .custom-nav > a {
        position: relative;
        width: fit-content;
        font-size: 8pt;
        height: min-content;
    }

    .contact-number {
        letter-spacing: 4px;
    }
}
 
@media all and (min-width: 480px) and (max-width: 768px) {

}
 
@media all and (max-width: 480px) { 

}
